<template>
  <div
    v-if="width >= 768"
    class="max-md:hidden min-h-screen min-w-full flex flex-col items-stretch gradient-bg"
  >
    <LayoutHeader />
    <Alert />
    <slot />
    <LayoutFooter />
  </div>
  <div
    v-else
    class="bg-black/30 fixed backdrop-blur-sm inset-0 z-[100] flex justify-center items-center"
  >
    <div class="box-shadow-size">
      <div class="w-full flex flex-col h-full">
        <p class="text-center text-heading2med">
          {{ $t("commons.alerts.size.subTitle") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// IMPORTS //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LIBRARIES
// COMPONENTS
import LayoutHeader from "~/components/Modules/Layout/Header.vue";
import LayoutFooter from "~/components/Modules/Layout/Footer.vue";
import Alert from "~/components/Styleguide/Molecule/Alert.vue";

// TYPES
// SERVICES
import { prepareVersion } from "~/services/prepareGeneral";

// COMPOSABLES
const { fetchVersion } = generalFetch();
const { t } = useI18n();

// STORES
const mainStore = useMainStore();

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////// LOGIC /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// DEFINE & DECLARE
// USE
const { app } = useRuntimeConfig();
const { backendUrl, backendVersion } = app;

// REF
const width = ref(window.innerWidth);

// COMPUTED
window.addEventListener("resize", myFunction);

function myFunction() {
  width.value = window.innerWidth;
}

// BASIC LOGIC
const { versionURL } = prepareVersion(backendUrl, backendVersion);
const appVersion = await fetchVersion(versionURL);

mainStore.setAppVersion(appVersion as string);

// MOUNTED
// UNMOUNTED
</script>

<style scoped lang="scss">
.box-shadow-size {
  @apply text-blue-500 flex flex-col max-w-3xl w-full mx-3 p-8 bg-white rounded-3xl;
  box-shadow:
    35px 15px 85px 0px rgba(11, 153, 255, 0.25),
    0px 35px 85px 0px rgba(117, 99, 255, 0.25),
    -35px 15px 85px 0px rgba(250, 79, 79, 0.25);
  outline: none;
  border: 2px solid transparent;
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(131deg, #fa4f4f 54.41%, #7563ff 68.99%, #0b99ff 83.58%);
}
</style>
